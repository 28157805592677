import { defineNuxtPlugin, useState } from '#app';
import { useRequestHeaders } from '#app';

// Tailwind breakpoints
const breakpoints = {
    sm: 640,
    md: 768,
    lg: 1024,
};

// Helper to detect device type from User-Agent
const getDeviceFromUserAgent = (userAgent: string) => {
    const isMobile = /Mobile|Android|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    const isTablet = /iPad|Tablet/i.test(userAgent);
    const isDesktop = !isMobile && !isTablet;

    return {
        isMobile,
        isTablet,
        isDesktop,
        isMobileOrTablet: isMobile || isTablet,
    };
};

export default defineNuxtPlugin((nuxtApp) => {
    const updateDeviceType = () => {
        const width = window.innerWidth;
        const deviceState = useState('deviceState');

        deviceState.value = {
            isMobile: width < breakpoints.md,
            isTablet: width >= breakpoints.md && width < breakpoints.lg,
            isDesktop: width >= breakpoints.lg,
            isMobileOrTablet: width < breakpoints.lg,
        };
    };

    if (typeof window === 'undefined') {
        // SSR: Detect device type using User-Agent
        const headers = useRequestHeaders(['user-agent']);
        const userAgent = headers['user-agent'] || '';
        const device = getDeviceFromUserAgent(userAgent);

        const deviceState = useState('deviceState', () => device);
        deviceState.value = device; // Initialize device state during SSR
    } else {
        // CSR: Initialize and manage resize listener
        const deviceState = useState('deviceState', () => ({
            isMobile: false,
            isTablet: false,
            isDesktop: false,
            isMobileOrTablet: false,
        }));

        const handleResize = () => {
            updateDeviceType();
        };

        updateDeviceType(); // Initial update for CSR

        window.addEventListener('resize', handleResize);

        // Cleanup listener on app unload
        nuxtApp.hook('app:rendered', () => {
            window.removeEventListener('resize', handleResize);
        });
    }
});
