
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexG125_457uuudIVHCygPuU5sv9tn_ZxTyzUgTjzGpWCIdgMeta } from "/var/www/parsitrip.com/front/pages/[slug]/index.vue?macro=true";
import { default as index9tO95GAAbtMWrBQj_eVIYdZfjwFt3Pe5Vyh9wQ7nCmUMeta } from "/var/www/parsitrip.com/front/pages/airport/[slug]/index.vue?macro=true";
import { default as indexbs1qctnDO7zxoSv_45UIx2t9kBxjgSf3WKmPeqrNh5eoYMeta } from "/var/www/parsitrip.com/front/pages/airport/index.vue?macro=true";
import { default as index2009q_45OuhZ45Ng_JxKSQ5rn3BOMzafnQn3qq42_awwAMeta } from "/var/www/parsitrip.com/front/pages/bus/index.vue?macro=true";
import { default as indexInnzcNuDVTGIsiAawrHdQ9qy2zUX0bPra98r5_iHlJAMeta } from "/var/www/parsitrip.com/front/pages/flight/[slug]/index.vue?macro=true";
import { default as index63Y9sQokK7gS4WtLOgOHlTIBjs6i2UzDF7NiUGR5secMeta } from "/var/www/parsitrip.com/front/pages/flight/index.vue?macro=true";
import { default as indexdKwrW23FVySnbo3MqiToALXzpqwkcJ9aqhg1kV25L_458Meta } from "/var/www/parsitrip.com/front/pages/flight/redirect/index.vue?macro=true";
import { default as indexgICfNeXO3dKJXqqdDfw0QYr45ZqTb9KhPC1dJnDnNz4Meta } from "/var/www/parsitrip.com/front/pages/g-tour/index.vue?macro=true";
import { default as index9u_E3w_GlwwiFxuEU_45LrGg0pIjq7JVuhe1CMCAP5MXQMeta } from "/var/www/parsitrip.com/front/pages/hotel/index.vue?macro=true";
import { default as indexdcpnuxsnbceztae6BPRjqv5_K5D1pvlhX2FxJfa68D8Meta } from "/var/www/parsitrip.com/front/pages/i-flight/index.vue?macro=true";
import { default as indexEyWp9rT_45BE1InSAmhbS5lrJ6_45Dug_45nmVb0Jo8QFnk18Meta } from "/var/www/parsitrip.com/front/pages/i-hotel/index.vue?macro=true";
import { default as indexgjNSIBMtmQ3udX4EuuT_45QiWsky9XbDaSHMStsoqreGQMeta } from "/var/www/parsitrip.com/front/pages/index.vue?macro=true";
import { default as indexr1npxgrkKO3CpgqCuAQAsAlUZL2zG3eaaS5IqCurckUMeta } from "/var/www/parsitrip.com/front/pages/tour/index.vue?macro=true";
import { default as indexrOLLNlrC0JrVT07pXYl0HBI2PSoOqYmEAAb4s5dMYS8Meta } from "/var/www/parsitrip.com/front/pages/train/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/var/www/parsitrip.com/front/pages/[slug]/index.vue")
  },
  {
    name: "airport-slug",
    path: "/airport/:slug()",
    component: () => import("/var/www/parsitrip.com/front/pages/airport/[slug]/index.vue")
  },
  {
    name: "airport",
    path: "/airport",
    component: () => import("/var/www/parsitrip.com/front/pages/airport/index.vue")
  },
  {
    name: "bus",
    path: "/bus",
    component: () => import("/var/www/parsitrip.com/front/pages/bus/index.vue")
  },
  {
    name: "flight-slug",
    path: "/flight/:slug()",
    component: () => import("/var/www/parsitrip.com/front/pages/flight/[slug]/index.vue")
  },
  {
    name: "flight",
    path: "/flight",
    component: () => import("/var/www/parsitrip.com/front/pages/flight/index.vue")
  },
  {
    name: "flight-redirect",
    path: "/flight/redirect",
    component: () => import("/var/www/parsitrip.com/front/pages/flight/redirect/index.vue")
  },
  {
    name: "g-tour",
    path: "/g-tour",
    component: () => import("/var/www/parsitrip.com/front/pages/g-tour/index.vue")
  },
  {
    name: "hotel",
    path: "/hotel",
    component: () => import("/var/www/parsitrip.com/front/pages/hotel/index.vue")
  },
  {
    name: "i-flight",
    path: "/i-flight",
    component: () => import("/var/www/parsitrip.com/front/pages/i-flight/index.vue")
  },
  {
    name: "i-hotel",
    path: "/i-hotel",
    component: () => import("/var/www/parsitrip.com/front/pages/i-hotel/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/parsitrip.com/front/pages/index.vue")
  },
  {
    name: "tour",
    path: "/tour",
    component: () => import("/var/www/parsitrip.com/front/pages/tour/index.vue")
  },
  {
    name: "train",
    path: "/train",
    component: () => import("/var/www/parsitrip.com/front/pages/train/index.vue")
  }
]