// plugins/exposeIpAndIranCheck.ts
export default defineNuxtPlugin((nuxtApp) => {
    const event = useRequestEvent();

    if (event) {
        const { context } = nuxtApp.ssrContext?.event || {};
        if (context) {
            const ipState = useState<string>('ip', () => context.ip || '');
            const isIranState = useState<boolean>('isIran', () => context.isIran || false);

            ipState.value = context.ip;
            isIranState.value = context.isIran;
        }
    }
});
