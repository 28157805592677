<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError
})
useHead({
  title: props.error?.statusCode === 404 ? "404 - صفحه مورد نظر یافت نشد" : "خطا در بارگذاری صفحه",
  meta: [
    { name: "robots", content: props.error?.statusCode === 404 ? "noindex, nofollow" : "index, follow" },
    { property: "og:title", content: props.error?.statusCode === 404 ? "404 - صفحه یافت نشد" : "خطا" },
    { property: "og:description", content: props.error?.statusCode === 404 ? "متاسفانه صفحه‌ای که به دنبال آن هستید وجود ندارد." : "خطایی در پردازش درخواست شما رخ داده است." }
  ],
  bodyAttrs: {
    class: 'overflow-hidden'
  }
})
console.log(props.error)

</script>

<template>
  <lazy-nuxt-layout no-hero>
    {{error}}
  </lazy-nuxt-layout>
</template>
